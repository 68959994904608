@use "mixin";

.pc {
    display: block;

    @include mixin.sp {
        display: none;
    }
}

.sp {
    display: none;

    @include mixin.sp {
        display: block;
    }
}

body {
    background-image: url(../images/common_bg.png);

    @include mixin.sp {
        background-image: url(../images/common_bg_sp.png);
    }

    --loading:0%;
}