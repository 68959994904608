@charset 'utf-8';
@use 'foundation/mixin';


body.appear .splashbg1,
body.appear .splashbg2{
  display:block;
}
/*右に消えるエリア*/
body.appear .splashbg1{
  animation-name:PageAnime;
  animation-duration:1.2s;
  animation-timing-function:ease-in-out;
  animation-fill-mode:forwards;
    content: "";
    position:fixed;
    z-index: 99;
    width: 100%;
    height: 100vh;
    top: 0;
    left:50%;
    transform: scaleX(1);
    background-color: #0a0a0a;/*伸びる背景色の設定*/
}
@keyframes PageAnime{
  0% {
    transform-origin:left;
    transform:scaleX(1);
  }
  50% {
    transform-origin:right;
  }
  100% {
    transform-origin:right;
    transform:scaleX(0);
  }
}

/*左に消えるエリア*/
body.appear .splashbg2{
  animation-name:PageAnime2;
  animation-duration:1.2s;
  animation-timing-function:ease-in-out;
  animation-fill-mode:forwards;
    content: "";
    position:fixed;
    z-index: 99;
    width: 100%;
    height: 100vh;
    top: 0;
    right:50%;
    transform: scaleX(1);
    background-color: #0a0a0a;/*伸びる背景色の設定*/
}

@keyframes PageAnime2{
  0% {
    transform-origin:right;
    transform:scaleX(1);
  }

  50% {
    transform-origin:left;
  }
  100% {
    transform-origin:left;
    transform:scaleX(0);
  }
}

// header,main{
//   opacity: 0;/*はじめは透過0に*/
// }

body.appear {

    header,main{
    animation-name:PageAnimeAppear;
    animation-duration:1s;
    animation-delay:0.2s;
  }
}
@keyframes PageAnimeAppear{
  0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
  }
}


.loaded {
  opacity: 0;
  display: none !important;
}

.loading {
  // opacity: 1;
  transition: all 1s;

  width: 100vw;
  height: 100vh;

  position: fixed;
  z-index: 9999;

  background-color: #0A0A0A;

  display: flex;
  justify-content: center;
  align-items: center;

  .loading__container {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include mixin.sp {
      width: auto;
    }

    .loading__content-image {
      width: 15%;
      height: auto;

      animation: loadingImageAnime 3s linear infinite;

      @keyframes loadingImageAnime{
        from {
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
      }
    
    }

    .loading__content-bar {
      border: 1px solid #00ADEF;
      width: 80%;
      margin: 1rem 0;
      padding: 1rem 1rem 0 1rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .loading__bar {
        position: relative;
        height: 1.5rem;
        width: 100%;
        border: 1px solid #D9C400;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: var(--loading);
          height: 100%;
          background-color: #D9C400;
          
          // animation: openingline 2s forwards;
        }
        
        @keyframes openingline {
          100% {
            width: 100%;
          }
        }

        // &::before {
        //     content: '';
        //     position: fixed;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100vh;
        //     background-color: #111;
        //     animation: opening 2.1s forwards;
        // }
        // @keyframes opening {
        //   99% {
        //     opacity: 1;
        //     z-index: 1;
        //   }
        //   100% {
        //     opacity: 0;
        //     z-index: -1;
        //   }
        // }
        
      }

      .loading__text {
        color:#00ADEF;
      }
      
    }

  }

}



header {

}

.haeder__bar {
  width:100%;
  position: fixed;
  top:0;
  right: 0;

  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  align-items: center;
  padding: 10px 20px;
  font-size: 1em;

  z-index: 9998;

  @include mixin.sp {
    flex-direction: column;
    align-items: end;
  }

  &-time {
    color:#C5C5C5;
    font-size: 2.4rem;
    display: none;
    margin-top: 2.8rem;
    margin-right: 1rem;

    &.active {
      display:block;
    }

    @include mixin.sp {
      font-size: 1.4rem;
      margin-top: 2rem;
    }
    
  }

  &-icon {
    display: block;
    position: relative;
    top: 1.5rem;
    right: 1rem;
    width: 10rem;
    height:5rem;
    text-align: right;
    cursor: pointer;

    @include mixin.sp {
      top: 2rem;
      right: -2rem;
    }
  }

  .haederBar__icon {
    
    &-line {
      display: inline-block;
      transition: all .4s;/*アニメーションの設定*/
      position: absolute;
      left: 13px;
      height: 2px;
      background-color: #D9C400;

      z-index: 999;

      &:nth-of-type(1) {
        top:22px;	
        width: 50%;
      }
      &:nth-of-type(1).active {
        top: 20px;
        left: 16px;
        transform: translateY(6px) rotate(-45deg);
        width: 35%;
      }
      &:nth-of-type(2) {
        top:29px;
        width:50%;
      } 
      &:nth-of-type(2).active {
          top: 32px;
          left: 16px;
          transform: translateY(-6px) rotate(45deg);
          width: 35%;
      }
    }
    
  }
}

.header__nav {
  position: fixed;
  top:0;
  width:100%;
  right: -120%;
  height: 100vh;
  transition: all 0.6s;
  z-index: 999;
  background-color: #0A0A0A;
  padding-top:9rem;

  &.active {
    right:0;
  }
}

.header__nav-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    // padding-left: 4rem;
  }

  .headerNav__menu-item {
    border-bottom: 1px solid #333;
    width: 100%;

  }


  .headerNav__menu-item .headerNav__menu-link {
    position: relative;
    color: #C5C5C5;
    text-decoration: none;
    font-size: 3.2em;
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    padding-left: 8rem;
    @include mixin.sp {
      font-size: 1.8em;
      padding-left: 2rem;
    }
  }
  .headerNav__menu-item .headerNav__menu-link:hover,
  .headerNav__menu-item.active {
    color: #0A0A0A;
    cursor: pointer;
  }


  .headerNav__menu-link:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 0%;
    height: 100%;
    background: #00ADEF;
    transform: translate3d(-100%, 0, 0);
    transition: .3s;
  }
  
  /* 疑似要素(hover) */
  .headerNav__menu-link:hover:before {
    transform: translate3d(0, 0, 0);
    width: 100%;
  }



  .headerNav__menu-text {
    position: relative;
    z-index: 1;
    transition: .3s;
  }

  .language__switch {
    padding: 2.5rem 20px;
    padding-left: 8rem;
    font-size: 1.8rem;
    width: 100%;

    z-index:1000;

    color: #C5C5C5;
    text-align: left;

    @include mixin.sp {
      font-size: 1.4rem;
      text-align: center;
      padding: 5rem 20px;
    }
    

    &-link {
      text-decoration: none;
      font-size: 1.2rem;

    }

    &-text {
      margin:0 0.5rem;
    }
  }