//変数
$font_base_size:62.5%;

$font_pc_h1:7.2rem;
$font_sp_h1:4.2rem;

$font_pc_h2:2.7rem;
$font_sp_h2:2.4rem;

$font_pc_sub_title:3.6rem;
$font_sp_sub_title:2.0rem;


// $font_pc_h3:0rem;
// $font_sp_h3:0rem;

// $font_pc_h4:0rem;
// $font_sp_h4:0rem;

$font_pc_text:1.8rem;
$font_sp_text:1.4rem;

$font_pc_small_text:1.6rem;
$font_sp_small_text:1.2rem;

/* カラー変数 */
$base_font_color:#3a3a3a;
$att_font_color:#ff0000;