$sp: 768px;
$tablet: 1160px;

@mixin pc {
  @media (min-width: ($sp+1)){
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin sp {
  @media (max-width: $sp) {
    @content;
  }
}