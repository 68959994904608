@use "sass:math";
@use 'foundation/mixin';

.speakers__title {
    margin-bottom:10rem;

    @include mixin.sp {
        margin-bottom:10rem;
    }


}

.speakers__list {
    margin-top:3rem;
    padding: 0 2.5rem;
    width:100%;
    display: flex;
    flex-wrap: wrap;

    @include mixin.sp {
        padding: 0 3rem;
    }
    
}


.speakersList__item {
    width:25%;
    padding:2.5rem;
    position: relative;

    @include mixin.sp {
        width:50%;
        padding:1.75rem;
    }

    &::before {
        position: absolute;
        top:-1.25rem;
        left: -1.25rem;
        content: "";
        background-image: url(../images/speaker_box_icon.png);
        background-size: contain;
        width: 2rem;
        height: 2rem;

        @include mixin.sp {
            top:0rem;
            left: 0rem;
        }
        
    }
    @include mixin.sp {
        &:nth-child(2n) {
            &::before  {
                top:0rem;
                left: -1.25rem;
            }
        }
    }

    &:nth-child(4n) {
        &::after  {
            position: absolute;
            top:-1.25rem;
            right: -1.25rem;
            content: "";
            background-image: url(../images/speaker_box_icon.png);;
            background-size: contain;
            width: 2rem;
            height: 2rem;
        }
    }

    @include mixin.sp {
        &:nth-child(2n) {
            &::after  {
                position: absolute;
                top:0rem;
                right:0rem;
                content: "";
                background-image: url(../images/speaker_box_icon.png);;
                background-size: contain;
                width: 2rem;
                height: 2rem;
            }
        }
    }
    
    // &:nth-last-child(n+4) {
    //     &::after  {
    //         position: absolute;
    //         bottom:-1.25rem;
    //         left: -1.25rem;
    //         content: "";
    //         background-image: url(../images/speaker_box_icon.png);;
    //         background-size: contain;
    //         width: 2rem;
    //         height: 2rem;
    
    //     }
    // }
}
.last_line {
    .speakersList__item {
        &::after  {
            position: absolute;
            top: auto;
            bottom:-1.25rem;
            left: -1.25rem;
            content: "";
            background-image: url(../images/speaker_box_icon.png);;
            background-size: contain;
            width: 2rem;
            height: 2rem;
        }

        @include mixin.sp {
            &::after  {
                content:none;
            }
            &:nth-child(2n) {
                &::after  {
                    position: absolute;
                    top:0rem;
                    left: auto;
                    right:0rem;
                    content: "";
                    background-image: url(../images/speaker_box_icon.png);;
                    background-size: contain;
                    width: 2rem;
                    height: 2rem;
                }
            }
            &:nth-last-child(2) {
                &::after  {
                    position: absolute;
                    top:auto;
                    bottom: 0;
                    left: 0;
                    right:auto;
                    content: "";
                    background-image: url(../images/speaker_box_icon.png);;
                    background-size: contain;
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }

}
.plus__icon-upper {
    position: absolute;
    top:-1.25rem;
    right: -1.25rem;
    content: "";
    background-image: url(../images/speaker_box_icon.png);;
    background-size: contain;
    width: 2rem;
    height: 2rem;

    @include mixin.sp {
        top:auto;
        bottom:0;
        right:0;
    }
}
.plus__icon-lower {
    position: absolute;
    bottom:-1.25rem;
    right: -1.25rem;
    content: "";
    background-image: url(../images/speaker_box_icon.png);;
    background-size: contain;
    width: 2rem;
    height: 2rem;
    

    @include mixin.sp {
        bottom:0;
        left: -1.25rem;
    }

}


.speakersListItem__box {
    &-image {
        width:100%;
        height: 100%;

        margin-bottom: 2rem;
    }

}

.speakersListItemBox__caption {
    font-family: Saira, sans-serif;
    color: #fff;
    font-style: normal;
    text-align: center;

    &-name {
        font-size: 2.8rem;
        line-height: 1;

        @include mixin.sp {
            font-size: 1.8rem;
        }
        
    }

    &-text {
        font-weight: regular;
        font-size: 1.8rem;
        margin-top: 1rem;

        @include mixin.sp {
            font-size: 1.4rem;
        }
        
    }

}