@use "mixin";

.pc {
    display: block;

    @include mixin.sp {
        display: none;
    }
}

.sp {
    display: none;

    @include mixin.sp {
        display: block;
    }
}