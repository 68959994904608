@use 'foundation/mixin';

.btn__container {
    display: flex;
    justify-content: center;

    margin-top: 8rem;
    @include mixin.sp {
        margin-top: 8rem;
    }

}

.btn__content {
    width: 50rem;
    height: 9rem;

    @include mixin.sp {
        width: 80%;
        height: 5.6rem;
    }
}

.btn {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    text-decoration:none;
    font-weight:500;
    font-size:17px;
    letter-spacing: 0.045em;
}

.btn svg {
    position: absolute;
    top: 0;
    left: 0;

    width: 50rem;
    height: 9rem;

    @include mixin.sp {
        width: 100%;
        height: 5.6rem;
    }

}

.btn svg rect {
    //stroke: #EC0033;
    stroke-width: 2.5;
    stroke-dasharray: 353, 0;
    stroke-dashoffset: 0;
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease;
    filter: drop-shadow(0px 0px 15px #00ADEF);

    width: 49rem;
    height: 8rem;

    @include mixin.sp {
        width: 98%;
        height: 4.6rem;
    }
}

.btn span{
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  font-size: 3.2rem;

  width: 100%;
  height: 100%;

  @include mixin.sp {
    font-size: 1.8rem;
  }
}

.btn:hover svg rect {
    stroke-width: 2.5;
    stroke-dasharray: 200, 1000;
    stroke-dashoffset: 485;
}