@use 'foundation/mixin';
@use "sass:math";

.text__container {
    width: 100%;
}

.text__wrap {
    display: inline-block;
    overflow: hidden;
    position: relative;
    margin: 0.5rem auto; 

    @include mixin.sp {
        margin: 0rem auto; 
    }

    @keyframes text_bg_slide {
        0% {
            transform: translate(0,0);
            width: 0;
        }
        100% {
            transform: translate(0,0);
            width: 100%;
        }
    }
    .text__content {
        font-family: "Saira", sans-serif;
        color: #0a0a0a;
        font-style: normal;
        font-weight: 500;
        font-size: math.div(28px, 1440px) * 100vw;
        letter-spacing: 0;
        // text-align: start;
        padding: 0 1rem;
        overflow:hidden;
        white-space: nowrap;

        width: 100%;
        height: 100%;

        position: relative;
        @include mixin.sp {
            font-size: 1.6rem;
        }
    }

    .text__bg,.summit__text-bg {
        position: absolute;
        top:0;
        left: 0;
        background: #ffffff;
        width: 100%;
        height: 85%;
        z-index: -1;
        transform: translateX(-100%);

        &.move{
            animation-name: text_bg_slide;
            animation-duration:.5s;
            
            animation-fill-mode: forwards;
        }

        @include mixin.sp {
            height: 65%;
        }
    } 

    
}




@keyframes passing-bar{
	0% {
		left: 0;
		width: 0;
	}
	50% {
		left: 0;
		width: 100%;
	}
	51% {
		left: 0;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 0;
	}
}
@-webkit-keyframes passing-img{
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes passing-img{
	0% { opacity:0; }
	50% { opacity:0; }
	100% { opacity:1; }
}

.summit__logos .summit__logo{
    display: block;
	text-align: center;
}


.summit__logos .summit__logo-bar {
	position: relative;
	display: inline-block;
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

    @include mixin.sp {
        width: 75%;
    }
}
.summit__logos .summit__logo-img {
	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.summit__logos .summit__logo-bar:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	/* 任意の値 */
	background: #F3F3F3;
}


.summit__logos.move .summit__logo-bar:before {
	-webkit-animation: passing-bar 1s ease 0s 1 normal forwards;
	animation: passing-bar 1s ease 0s 1 normal forwards;
}

.summit__logos.move .summit__logo-img {
	-webkit-animation: passing-img 0s ease .5s 1 normal forwards;
	animation:passing-img 0s ease .5s 1 normal forwards;
}


.slide-in {
	overflow: hidden;
    display: inline-block;
}

.slide-in_inner {
	display: inline-block;

}

/*左右のアニメーション*/
.leftAnime,.leftAnime_highlights{
    opacity: 0;
}
.leftAnimeInner {
    color: #ffffff;
}
.slideAnimeLeftRight {
	animation-name:slideTextX100;
    animation-delay:0.5s;
	animation-duration:0.5s;
	animation-fill-mode:forwards;
    opacity: 0;
    
}

@keyframes slideTextX100 {
  from {
    width: 0%;
    opacity: 0;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}

// .slideAnimeRightLeft {
// 	animation-name:slideTextX-100;
// 	animation-duration:1.2s;
// 	animation-fill-mode:forwards;
//     opacity: 0;
// }


// @keyframes slideTextX-100 {
//   from {
// 	// transform: translateX(100%);/*要素を右の枠外に移動*/
//     opacity: 0;
//   }

//   to {
// 	// transform: translateX(0);/*要素を元の位置に移動*/
//     opacity: 1;
//   }
// }