@charset 'UTF-8';

@use 'mixin';
@use 'variable';

html {
  font-size: variable.$font_base_size;
  line-height: 1.5;
  letter-spacing:0.5px;
}

h1 {
  font-size: variable.$font_pc_h1;
  @include mixin.sp {
    font-size: variable.$font_sp_h1;
  }
}

h2 {
  font-size: 2.7rem;
}

p {
  font-size: 1.8rem;
}

.small {
  font-size: 1.6rem;
}

