@use 'foundation/mixin';
@use "sass:math";

.footer__inner {
	padding-top: 5rem;
	padding-bottom: 5rem
}

.footer__logo {
	width: 36rem;
	margin: 0 auto;

	@include mixin.sp {
		width: 19.6rem;
	}
}

.footer__sns {
	width: 5.6rem;
	margin: .8rem auto 0
}

.footer__copyright {
	margin-top: 3rem;
	font-family: "Saira", sans-serif;
	color: #fff;
	font-style: normal;
	font-weight: 500;
	font-size: 1.8rem;
	letter-spacing: 0;
	line-height: 1;
	text-align: center
}

.footer__textScroll {
	margin-bottom: 3rem;
	overflow: hidden;
	width: 100%
}

.footer__textScrollInner {
	display: flex;
	justify-content: center;
	align-items: center
}

.footer__textScrollItem {
	flex-shrink: 0;
	animation: loopfooter 40s linear infinite;
	width: 290.5rem
}

@keyframes loopfooter {
	0% {
		transform: translateX(100%)
	}

	100% {
		transform: translateX(-100%)
	}
}

.mv {
	position: relative;
	height: 800vh;
	z-index: 1;

	@include mixin.sp {
		height: 300vh;
	}
}

.mv__inner {
	width:100%;
}

.mv__content {
	position: sticky;
	top: 0;
	overflow: hidden;
}

.mv__frame {
	padding: 2rem 4rem 0;

	@include mixin.sp {
        padding:2rem 1rem 0;
    }	
}

.mv__bg {
	position: absolute;
	top: 10%;
	left: 7.5%;
	width: 85%;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
	transform-origin: center center;
	transform: scale(1);

	@include mixin.sp {
		top: 5.9%;
        left: 50%;
        width: 82.5%;
		// transform: translateX(-50%) scale(1) !important;
	}
}

.mv__video { 
	width: 100%;
	height: auto;

	@include mixin.sp {
		width: 100%;
		height: 76.8rem;
		object-fit: cover;
	}
}

.mv__bg img {
	height: 100%
}

.mv__title {
	transition: .3s;
	width: 80%;
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
    top: 25%;

	@include mixin.sp {
		width: 28.8rem;
		top: 23.4rem;
	}
}

.mv__day {
	transition: .3s;
	position: absolute;
	top: 50%;
	transform: translateX(-50%);
	left: 50%;
	font-family: "Saira", sans-serif;
	color: #fff;
	font-style: normal;
	font-weight: 500;
	font-size: 4rem;
	letter-spacing: 0;
	line-height: 1;
	text-align: center;

	@include mixin.sp {
		top: 40rem;
		font-size: 2.4rem;
		text-align: left;
		width: 75%;
	}
}

.mv__text {
	transition: .3s;
	position: absolute;
	top: 60%;
	transform: translateX(-50%);
	left: 50%;
	font-family: "Saira", sans-serif;
	color: #fff;
	font-style: normal;
	font-weight: 500;
	font-size: 2.4rem;
	letter-spacing: 0;
	line-height: 1.5;
	text-align: center;
	width:100%;

	@include mixin.sp {
		top: 43.5rem;
		font-size: 1.4rem;
		text-align: left;
		width: 75%;
	}
}

.mv__logo {
	width: 25.8rem;
	transition: .3s;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 45%;
	left: 50%;
	opacity: 0;

	@include mixin.sp {
		top: 50%;
	}
}

.wave {
	position: relative;
	z-index: 9999;
	bottom:5rem;
	
	.wave__content {

		background-color: #0a0a0a;
	
		&:nth-child(1) {
			height: 0.5rem;
			margin-bottom: 1.25rem;
		}

		&:nth-child(2) {
			height: 1.5rem;
			margin-bottom: 2.25rem;
		}

		&:nth-child(3) {
			height: 2.5rem;
		}

	}
}

.summit {
	height: auto;
	background-color: #0a0a0a;
	position: relative;
	z-index: 10;

	@include mixin.sp {
		height: 250vh;
	}
}

.summit__inner {
	// height: 100%;
	padding-bottom: 40rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	// position: relative;

	@include mixin.sp {
		padding-bottom: 16rem;
	}
}

.summit__upper {
    height: 250vh;
	margin-top: 25rem;
	
	@include mixin.sp {
		height: 150vh;
	}
}

.title__container {
    position: sticky;
    top: 50%;
    transform: translateY(-25%);
}

.summit__title {
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: -0.01em;
	line-height: 1.5;
	text-align: center;
	position: relative;

	@include mixin.sp {
		font-size: 4.4rem;
	}
}

.summit__title--shadow01 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0px 2px 2px rgba(0, 0, 0, .25)
}

.summit__title--shadow02 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 34px #298ab9
}

.summit__title--shadow03 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 30px #298ab9
}

.summit__title--shadow04 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0 0px 100px #298ab9
}

.summit__lead {
	// margin-top: 4rem;
	// font-family: "Saira", sans-serif;
	// color: #0a0a0a;
	// font-style: normal;
	// font-weight: 500;
	// font-size: 2.6rem;
	// letter-spacing: 0;
	// line-height: 2;
	// text-align: center;

	// @include mixin.sp {
	// 	font-size: 1.6rem;
	// }

	// text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.summit__leadInner {
	background: linear-gradient(transparent 0%, #fff 0%);
	display: inline;
	padding: 0 2rem
}

.summit__logos {
	margin-top: 8rem;
	display: flex;
	justify-content: center;
	gap: 0 12rem;

	@include mixin.sp {
		padding: 0 1rem;
		gap: 0 1.2rem;
	
	}
}

.summit__logo {
	width: 30rem
}

.photo01__inner {
	max-width: 124rem;
	margin: 0 auto
}

.photo01__item:nth-child(1) {
	aspect-ratio: 665/443;
	width: math.div(665,1440) * 100vw;
	margin-left: 5rem;

	img {
		height:60rem;
   	}

	@include mixin.sp {
		aspect-ratio: 320/213;
		width: math.div(320,375) * 100vw;
		margin-left: 1rem;

		img {
			height:30rem;

		}
	}
}

.photo01__item:nth-child(2) {
	margin-top: 10rem;
	
	margin-left: auto;
	text-align:right;

	aspect-ratio: 443/665;
	width: math.div(445,1440) * 100vw;

	img {
		height:90rem;
   	}

	@include mixin.sp {
		aspect-ratio: 213/320;
		width: math.div(213,375) * 100vw;
		margin-right: 1rem;

		img {
			height:50rem;
		}
	}
}

.photo01__item:nth-child(3) {
	margin-top: -7.6rem;
	aspect-ratio: 665/443;
	width: math.div(665,1440) * 100vw;

	img {
		height:60rem;
   	}

	@include mixin.sp {
		margin-left: 1rem;
		margin-top: 7.6rem;

		aspect-ratio: 320/213;
		width: math.div(320,375) * 100vw;

		img {
			height:30rem;

		}
	}
}

.photo01__item {
	overflow: hidden;

	&-inner{
		.photo01__item-img {
			display: block;
		}
		
	}
}

.photo01__lower {
	
	margin-top: 30rem;

	// &-clip {
	// 	-webkit-clip-path:inset(0 50%);
  	// 	clip-path:inset(0 50%);
	// }
	
	@include mixin.sp {
		margin-top: 15rem;
	}
}

.speakers {
	margin-top: 45rem;

	@include mixin.sp {
		margin-top: 20rem;
	}
}

.speakers__inner {
	max-width: 124rem;
	margin: 0 auto
}

.speakers__title {
	position: relative
}

.speakers__titleMain {
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 4.4rem;
	letter-spacing: .02em;
	line-height: 1;
	text-align: center;
	position: relative;
	z-index: 10;

	@include mixin.sp {
		font-size: 2.8rem;
	}
}

.speakers__titleBack {
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #555;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: .01em;
	line-height: 1;
	text-align: center;
	position: absolute;
	z-index: 1;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	text-align: center;
	-ms-filter: blur(2px);  /* ぼかしの設定(マイクロソフト) */
  	filter: blur(2px);

	@include mixin.sp {
		font-size: 4rem;
	}
}

.speakers__icon {
	display: flex;
	gap: 0 26rem
}

.speakers__icon--upper {
	margin-top: 8rem;
	margin-bottom: 6rem
}

.speakers__icon--lower {
	margin-top: 4rem;
	justify-content: flex-end
}

.speakers__iconPlus {
	position: relative;
	width: 4rem;
	height: 4rem;
	border-radius: 50%
}

.speakers__iconPlus::before,
.speakers__iconPlus::after {
	position: absolute;
	top: 100%;
	left: 50%;
	content: "";
	display: inline-block;
	width: 100%;
	height: 100%;
	border-top: 1px solid #fff;
	transform: translate(-50%, -50%)
}

.speakers__iconPlus:after {
	top: 0;
	left: -50%;
	transform: rotate(90deg)
}

.swiper-slide {
	max-width: 19rem;
	width: 100% !important;
	margin: 0 2rem
}

.swiper-slide.swiper-slide-active {
	max-width: 32rem;
	width: 100% !important
}

.swiper-slide-active .cardSpeakers__photo {
	padding: 3rem;
	border-radius: 20rem;
	border: .3rem solid #fff
}

.speakers__lower {
	max-width: 114rem;
	margin:0 auto;
	margin-top: 20rem;

	@include mixin.sp {
		margin-top: 10rem;
	}
}

.speakers__box {
	display: flex;
	justify-content: space-between;


	@include mixin.sp {
		display:block;
	}
}

.speakers__left {
	width: 50%;
	position: relative;

	@include mixin.sp {
		margin: 0 auto;
	}
	
}

.speakers__subtitle {
	position: sticky;
	top: 30rem;
	margin-bottom: 20rem;

	@include mixin.sp {
		margin-bottom: 6rem;
	}
}

.speakers__subtitleMain {
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 4.4rem;
	letter-spacing: .02em;
	line-height: 1;
	text-align: center;
	position: relative;
	z-index: 10;

	@include mixin.sp {
		font-size: 2.8rem;
	}
}

.speakers__subtitleBack {
	white-space: nowrap;
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #555;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: .01em;
	line-height: 1;
	text-align: center;
	position: absolute;
	z-index: 1;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	text-align: center;
	-ms-filter: blur(2px);  /* ぼかしの設定(マイクロソフト) */
  	filter: blur(2px); 

	@include mixin.sp {
		font-size: 4rem;
	}
	
}

.speakers__right {
	width: 50%;

	@include mixin.sp {
		// max-width: 100%;
		width: 100%;
		margin:0 auto;
	}
}

.speakers__item+.speakers__item {
	margin-top: 8rem
}

.cardSpeakers__img {
	position: relative;
}

.cardSpeakers__text {
	font-family: "Saira", sans-serif;
	color: #fff;
	font-style: normal;
	font-weight: 500;
	font-size: 2.8rem;
	letter-spacing: 0;
	line-height: 1.5;
	text-align: center;

	position: absolute;
	bottom: -8%;
	left: 50%;
	transform: translateX(-50%);

	width:40rem;

	@include mixin.sp {
		font-size: 1.8rem;
		width:100%;
	}
}

.speakers__button {
	margin-top: 15rem
}

.button01 {
	width: 50rem;
	height: 9rem;
	display: block;
	position: relative;
	margin: 0 auto;
	transition: filter .3s ease;

	@include mixin.sp {
		width: 31.4rem;
	}
	
}

.button01:hover {
	filter: brightness(1.3)
}

.button01__inner {
	display: block
}

.button01__bg {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0
}

.button01__bg img {
	filter: drop-shadow(0.2rem 0.2rem 2.5rem #00ADEF)
}

.textScroll {
	margin-top: 22rem;
	overflow: hidden;
	width: 100%
}

.textScroll__inner {
	display: flex;
	justify-content: center;
	align-items: center
}

.textScroll__item {
	flex-shrink: 0;
	animation: loop 40s linear infinite;
	// width: 290.5rem
	// width: 100%;
	width: 400%;

	@include mixin.sp {
		width: 700%;
	}
}

@keyframes loop {
	0% {
		transform: translateX(100%)
	}

	100% {
		transform: translateX(-100%)
	}
}

.events {
	// height: 760vh;
	// height: 500vh;

	@include mixin.sp {
		// height: 600vh;
		// margin-bottom:100vh;
	}
}

.events__inner {
	height: 100%;
	max-width: 124rem;
	margin: 0 auto;
	// padding-bottom: 40rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative;

	// @include mixin.sp {
	// 	padding-bottom: 20rem;
	// }
}

.events__upper {
	margin-top: 25rem;
    height: 250vh;

	@include mixin.sp {
		height: 150vh;
	}
}

.events__title {
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: -0.01em;
	line-height: 1.5;
	text-align: center;
	position: relative;

	@include mixin.sp {
		font-size: 4.4rem;
	}
}

.events__title--shadow01 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0px 2px 2px rgba(0, 0, 0, .25)
}

.events__title--shadow02 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 34px #298ab9
}

.events__title--shadow03 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 30px #298ab9
}

.events__title--shadow04 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0 0px 100px #298ab9
}

.events__lower {
	margin-top: 10rem;
	max-width: 136rem;
	width: 100%;
	padding:0 4rem;

	@include mixin.sp {
		padding:0 1rem;
		margin-top: 4rem;
	}
}

.events__box {
	display: flex;
	justify-content: space-between;

	@include mixin.sp {
		display:block;
	}
}

.events__left {
	margin-left: 12.9rem;
	position: relative;

	@include mixin.sp {
		margin-left: 0;
	}
}

.events__subtitle {
	position: sticky;
	// top: 30rem;
	margin-bottom: 8rem;
	@include mixin.sp {
		margin-bottom: 6rem;
	}

}

.events__subtitleMain {
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 4.4rem;
	letter-spacing: .02em;
	line-height: 1;
	text-align: center;
	position: relative;
	z-index: 10;

	@include mixin.sp {
		font-size: 2.8rem;
	}
}

.events__subtitleBack {
	white-space: nowrap;
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #555;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: .01em;
	line-height: 1;
	text-align: center;
	position: absolute;
	z-index: 1;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	text-align: center;
	-ms-filter: blur(2px);  /* ぼかしの設定(マイクロソフト) */
  	filter: blur(2px);

	@include mixin.sp {
		font-size: 4rem;
	}
}


.event__content {
	max-width: 136rem;
	width: 100%;
	height: 100%;
	padding: 8rem 5rem;
	padding:0;
	position: relative;

	display: flex;
    justify-content: center;

	margin-bottom:12rem;

	// @include mixin.sp {
	// 	text-align: center;
	// 	margin:0 auto;
	// }
}

.eventContent_bg {
	width: 100%;
	height: auto;
}

.event__list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 1040px;
	width: 80%;
	padding: 8rem 0;

	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;

	@include mixin.sp {
		grid-template-columns: 1fr;
		width: 90%;
		// padding: 4rem 0;
	}

	&-item {
		text-align: center;
		padding: 4rem 0;
		border-top: solid 0.5px #C5C5C5;
		border-bottom: solid 0.5px #C5C5C5;
		&:first-child {
			border-top: solid 1px #C5C5C5;
		}
		&:nth-child(odd) {
			border-right: solid 1px #C5C5C5;

			@include mixin.sp {
				border-right:none;
			}
		}

		&:first-child,&:nth-child(2){
			border-top: solid 1px #C5C5C5;
		}

		&:last-child,&:nth-last-child(2) {
			border-bottom: solid 1px #C5C5C5;
		}

		@include mixin.tablet {
			padding: 2rem 0;
		}

		@include mixin.sp {
			
		}
	}
	&-link {
		display: block;
		width: 70%;
		margin: auto;

		@include mixin.sp {
			width: 90%;
		}
	}
}

.eventList__item {

	&-text {
		margin-top: 2rem;
		font-family: "Saira", sans-serif;
		color: #fff;
		font-style: normal;
		font-weight: 500;
		font-size: 2.5rem;
		letter-spacing: 0;
		line-height: 1.3;
		text-align: center;
		@include mixin.tablet {
			font-size: 1.8rem;
		}

		@include mixin.sp {
			font-size: 1.8rem;
		}
	}
}



.eventList__item-text--btn {
	font-family: "Saira", sans-serif;
	color: #fff;
	font-style: normal;
	font-weight: 300;
	font-size: 2.5rem;
	text-align: center;
	margin:12rem 0 8rem 0;
}

// .event__list {
// 	display: flex;
// 	justify-content: center;
// 	width:100%;
// 	padding:6rem 2rem;
// 	flex-wrap:wrap;

// 	background-image: url(../images/event_content_bg.png);
// 	background-position: center center;
// 	background-size: contain;
// 	background-repeat: no-repeat;

// 	@media (max-width: 1115px) {
// 		background-image: url(../images/event_content_bg_tab.png);
// 		padding:6rem 2rem;
// 	}

// 	@include mixin.sp {
// 		display: block;
// 		background-image: url(../images/event_content_bg_sp.png);
// 		// padding: 6rem 2rem;
// 	}

	

// 	&-item {
// 		padding: 4rem 4rem 4rem 4rem; 
// 		width:33%;

// 		@media (max-width: 1115px) {
// 			width:50%;
// 		}

// 		@include mixin.sp {  
// 			text-align: center;
// 			width:100%;
// 			padding: 8rem 2rem 2rem 2rem; 

// 			&:first-child {
// 				padding: 2rem 2rem 2rem 2rem; 
// 			}

// 			&:last-child {
// 				padding: 8rem 2rem 4rem 2rem; 
// 			}
			
			
// 		}
		
// 	}

// 	&-link {
// 		display: block;
// 		width: 100%;
// 		height: 100%;
// 	}

// }

// .eventList__item {
// 	text-align: center;
// 	&-img {

// 		@include mixin.sp {
// 			max-width: 350px;
// 			width: 100%;
// 		}
		
// 	}

// 	&-text {
// 		margin-top: 2rem;
// 		font-family: "Saira", sans-serif;
// 		color: #fff;
// 		font-style: normal;
// 		font-weight: 500;
// 		font-size: 2.8rem;
// 		letter-spacing: 0;
// 		line-height: 1;
// 		text-align: center;

// 		@include mixin.sp {
// 			font-size: 1.8rem;
// 		}
// 	}

// }

// .events__right {
// 	max-width: 77rem;
// 	width: 100%
// }

// .events__item {
// 	max-width: 41rem
// }

// .events__item+.events__item {
// 	margin-top: 6rem
// }

// .events__item:nth-child(odd) {
// 	margin-right: auto
// }

// .events__item:nth-child(even) {
// 	margin-left: auto
// }




.cardEvents__img {
	overflow: hidden;
	border-radius: .5rem
}

.cardEvents__text {
	margin-top: 4rem;
	font-family: "Saira", sans-serif;
	color: #fff;
	font-style: normal;
	font-weight: 500;
	font-size: 2.8rem;
	letter-spacing: 0;
	line-height: 1.5;
	text-align: center
}

.communityEvents {
	// height: 500vh;
	// height: 200vh;

	@include mixin.sp {
		// height: 300vh;
	}
}

.communityEvents__inner {
	height: 100%;
	max-width: 124rem;
	margin: 0 auto;
	// padding-bottom: 40rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative
}

.communityEvents__upper {
	margin-top: 25rem;
    height: 250vh;

	@include mixin.sp {
		height: 150vh;
	}
}

.communityEvents__title {
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: -0.01em;
	line-height: 1.5;
	text-align: center;
	position: relative;

	@include mixin.sp {
		font-size: 4.4rem;
	}
}

.communityEvents__title--shadow01 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0px 2px 2px rgba(0, 0, 0, .25)
}

.communityEvents__title--shadow02 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 34px #298ab9
}

.communityEvents__title--shadow03 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 30px #298ab9
}

.communityEvents__title--shadow04 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0 0px 100px #298ab9
}

.communityEvents__lower {
	margin-top: 10rem;
	padding: 0 4rem;

	@include mixin.sp {
		margin-top: 3rem;
		padding: 0 1rem;
	}
}

.communityEvents__subtitle {
	position: relative;
	margin-bottom: 8rem;

	@include mixin.sp {
		margin-bottom: 3rem;
	}
}

.communityEvents__subtitleMain {
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 4.4rem;
	letter-spacing: .02em;
	line-height: 1;
	text-align: center;
	position: relative;
	z-index: 10;

	@include mixin.sp {
		font-size: 2.8rem;
	}
}

.communityEvents__subtitleBack {
	white-space: nowrap;
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #555;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: .01em;
	line-height: 1;
	text-align: center;
	position: absolute;
	z-index: 1;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	text-align: center;
	-ms-filter: blur(2px);  /* ぼかしの設定(マイクロソフト) */
  	filter: blur(2px);
	@include mixin.sp {
		font-size: 4rem;
	}
}

.communityEvents__list {
	display: flex;
	justify-content: center;
	padding: 0 15rem;
	gap: 0 6rem;

	@include mixin.sp {
		display: block;
		padding:0 3rem;

	}

}

.communityEvents__item {
	max-width: 54rem;
	padding: 2rem 0;
	
	@include mixin.sp {
		margin: 0 auto;
	}

	.cardcommunityEvents {	
		display: block;
		.cardcommunityEvents__img {
			position: relative;

			img {
				transition: opacity 0.5s;

				&:last-of-type {
					position: absolute;
					top: 0px;
					left: 0px;
					opacity: 0;
				}
			}

			&:hover {
				img {
					
					&:first-of-type {
						opacity: 0;
					}

					&:last-of-type {
						opacity: 1;
					}
				}
			}

		}
	}
}

.null {
	@include mixin.sp {
		display: none !important;
	}
	
}

.earth__inner {
	max-width: 74.4rem;
	margin: 0 auto;

	@include mixin.sp {
		padding: 0 3rem;
	}
}

.earth__video {
	width: 100%;

}

.partners {
	// height: 500vh;
	// margin-top: 69rem;
	margin-top: 40rem;
	position: relative;

	@include mixin.sp {
		margin-top: 0rem;
		margin-top: 25.1rem;
	}
}

.partners__inner {
	height: 100%;
    max-width: 124rem;
    margin: 0 auto;
	margin: 8rem auto 0;
    // padding-bottom: 40rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.partners__upper {
	position: sticky;
	// top: 50%;
	// margin-top: 50vh;
	// margin-bottom: 39rem;

	@include mixin.sp {
		transform: translateY(-50%);
	}
}

.partners__lower {
	margin-top: 12rem;

	@include mixin.sp {
		margin-top: 7rem;
	}
}

.partners__title {
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: -0.01em;
	line-height: 1.5;
	text-align: center;
	position: relative;

	@include mixin.sp {
		font-size: 4.4rem;
	}
}

.partners__title--shadow01 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0px 2px 2px rgba(0, 0, 0, .25)
}

.partners__title--shadow02 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 34px #298ab9
}

.partners__title--shadow03 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 30px #298ab9
}

.partners__title--shadow04 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0 0px 100px #298ab9
}

.partners__subtitle {
	position: relative;
	margin-bottom: 8rem;
	// margin-top: 13.4rem
}

.partners__subtitleMain {
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 4.4rem;
	letter-spacing: .02em;
	line-height: 1;
	text-align: center;
	position: relative;
	z-index: 10;

	@include mixin.sp {
		font-size: 2.8rem;
	}
}

.partners__subtitleBack {
	white-space: nowrap;
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #555;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: .01em;
	line-height: 1;
	text-align: center;
	position: absolute;
	z-index: 1;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	text-align: center;
	-ms-filter: blur(2px);  /* ぼかしの設定(マイクロソフト) */
  	filter: blur(2px);

	@include mixin.sp {
		font-size: 4rem;
	}
}

.partners__blkList {
	padding: 0 1rem;
}

.partners__blk+.partners__blk {
	margin-top: 8rem;
	@include mixin.sp {
		margin-top: 6rem;
	}
}

.partners__blktitle {
	display: block;
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 3.6rem;
	letter-spacing: .02em;
	line-height: 1;
	text-align: left;
	position: relative;
	z-index: 10;

	@include mixin.sp {
		font-size: 2.4rem;
	}
}

.partners__list {
	margin-top: 4rem;
	display: flex;
	flex-wrap: wrap;
	gap: 4rem
}
.partners__item {
	background-color: #ffffff;
}

.diamond {
	.partners__item {
		width: 50%;
		height: 18rem;

		.cardPartners {
			padding:3.6rem;

			&:hover {
				opacity: 0.6;
			}
		}

		@include mixin.sp {
			width:100%;
			.cardPartners {
				padding:2rem;
			}
		}
	}
}

.platinum {
	display: flex;
	gap: 2rem;
	// flex-wrap: nowrap;
	margin-top:2rem;

	@include mixin.sp {
		margin-top:1rem;
		width: 100%;
		gap: 0.5rem;
		.partners__item {
			width: 50%;
		}
	}

	.partners__item {
		width: 32%;
		max-width: 40rem;
		height: 16rem;

		.cardPartners {
			padding:2rem 4rem;

			&:hover {
				opacity: 0.6;
			}
		}

		@include mixin.sp {
			width: 48%;
			height: 8rem;
			.cardPartners {
				padding:0.2rem 3rem;
			}
		}
	}	
	
	
}

.gold,.pickup__partners {
	display: flex;
	width: 100%;
	gap: 1.5rem;
	flex-wrap: wrap;
	margin-top:2rem;
	

	.partners__item {
		width: 23%;
		height: 10rem;
		padding: 1.6rem;
		
		.cardPartners:hover {
			opacity: 0.6;
		}

		.cardPartners__img {
			display: flex;
			justify-content: center;
			width: 100%;
			height: 100%;
			img {
				object-fit: contain;
				height: 100%;
				width: auto;
			}
		}

	}

	@include mixin.sp {
		margin-top:1rem;
		width: 100%;
		column-gap: 0rem;
		row-gap: 1rem;
		justify-content: space-around;

		.partners__item {
			width: 46%;
			height: 6rem;
			padding: 1.2rem;

			.cardPartners__img {
				margin:0 auto;
			}

		}
	}

}

.pickup__partners {
	@include mixin.sp {
		justify-content: flex-start;
	}
}

.silver,.media_partners,.supporters,.vc_partners,.community_partners {
	display: flex;
	width: 100%;
	gap: 2rem;
	flex-wrap: wrap;
	margin-top:2rem;

	.partners__item {
		width: 18%;
		height: 8rem;

		.cardPartners {
			padding:1.2rem 2.4rem;

			&:hover {
				opacity: 0.6;
			}
			
			.cardPartners__img {
				display: flex;
				justify-content: center;
				width: 100%;
				height: 100%;
				img {
					object-fit: contain;
					height: 100%;
    				width: auto;
				}
			}
		}
	}
	

	@include mixin.sp {
		margin-top:1rem;
		width: 100%;
		column-gap: 0.5rem;
		row-gap: 1rem;
		justify-content: flex-start;

		.partners__item {
			width: 32%;
			height: 5rem;
			.cardPartners {
				padding:.8rem;
				display: flex;
        		justify-content: center;
				.cardPartners__img {
					display: flex;
        			justify-content: center;
				}
	
			}
		}
	}


}

.supporters__caution {
	font-size: 1.6rem;
	color: #FFFFFF;

	@include mixin.sp {
		font-size: 1.2rem;
	}

}

// .partners__blk:nth-child(1) .partners__list {
// 	gap: 2rem
// }

// .partners__blk:nth-child(1) .partners__item {
// 	width: 40rem
// }

.cardPartners {
	display: flex;
    align-items: center;
	height: 100%;
}

.highlights {
	margin-top: 42.8rem;
	@include mixin.sp {
		margin-top: 21.4rem;
	}
}

.highlights__inner {
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative
}

.highlights__title {
	font-family: "Saira Stencil One", sans-serif;
	color: #00adef;
	font-style: normal;
	font-weight: 700;
	font-size: 7rem;
	letter-spacing: -0.01em;
	line-height: 1.5;
	text-align: center;
	position: relative;

	@include mixin.sp {
		font-size: 4.4rem;
	}
}

.highlights__title--shadow01 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0px 2px 2px rgba(0, 0, 0, .25)
}

.highlights__title--shadow02 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 34px #298ab9
}

.highlights__title--shadow03 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 4px 0px 30px #298ab9
}

.highlights__title--shadow04 {
	width: 100%;
	position: absolute;
	transform: translateX(-50%);
	top: 0;
	left: 50%;
	text-shadow: 0 0px 100px #298ab9
}

.highlights__lead {
	margin-top: 7.2rem;
	font-family: "Saira", sans-serif;
	color: #0a0a0a;
	font-style: normal;
	font-weight: 500;
	// font-size: 2.6rem;
	letter-spacing: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;

	@include mixin.sp {
		font-size: 1.6rem;
	}
}

.highlights__leadInner {
	background: linear-gradient(transparent 0%, #fff 0%);
	display: inline;
	padding: 0 2rem
}

.photo02 {
	margin-top: 32.4rem;

	@include mixin.sp {
		margin-top: 17.2rem;
	}
}

.photo02__inner {
	max-width: 114rem;
	margin: 0 auto;
	padding-bottom: 30rem;

	@include mixin.sp {
		padding-bottom: 10rem;
	}
}

.photo02__row1 {
	display: flex;
	justify-content: space-between;

	margin-bottom:5.5rem;

	&-img {
		object-fit: contain;
		&:first-child {
			width:37.1rem;
			margin-bottom:19.5rem;
			height: auto;
			aspect-ratio: 371 / 247; 
			
		}

		&:nth-child(2) {
			margin-top:19.5rem;
			width:43.4rem;
			height: auto;
			aspect-ratio: 434 / 289; 
		}

	}

	@include mixin.sp {
		flex-direction: column;
		
		&-img {
			&:first-child {
				width:23rem;
				margin-bottom: 2.4rem;
				margin-right: auto;
			}

			&:nth-child(2) { 
				margin-top: 0;
				width:19.5rem;
				margin-left: auto;
			}
		}
	}
}

.photo02__row2 { 
	display: flex;
	justify-content: center;

	margin-bottom:8.7rem;
	&-img {
		width:63.1rem;
	}

	@include mixin.sp {
		&-img {
			width:31.5rem;
		}
	}

}

.photo02__row3 { 
	display: flex;
	justify-content: space-between;
	&-img {
		object-fit: contain;
		&:first-child {
			width:44.25rem;
			margin-bottom: 24.4rem;
			aspect-ratio: 442 / 295; 
		}

		&:nth-child(2) {
			margin-top: 8.4rem;
			width:45.5rem;
			height: auto;
			aspect-ratio: 455 / 304; 
		}

	}

	@include mixin.sp {
		
		&-img {
			&:first-child {
				width:50%;
				margin-bottom: 13.2rem;
				margin-right: auto;
			}

			&:nth-child(2) { 
				margin-top: 8rem;
				width:50%;
				margin-left: auto;
			}
		}
	}

}

.photo02__item {
	max-width: 57rem
}

.photo02__item:nth-child(even) {
	margin-left: auto
}

.photo02__item+.photo02__item {
	margin-top: 26.6rem
}

.table-info__tr {
	display: flex
}

@media screen and (max-width: 750px) {
	.table-info__tr {
		flex-direction: column
	}
}

.table-info__tr+.table-info__tr {
	margin-top: 2rem
}

.table-info__th {
	color: #fff;
	width: 15.6rem;
	font-size: 1.4rem;
	line-height: 1.9285714286;
	letter-spacing: .06em;
	font-weight: 700
}

@media screen and (max-width: 750px) {
	.table-info__th {
		width: 100%
	}
}

.table-info__td {
	color: #fff;
	font-size: 1.4rem;
	line-height: 1.9285714286;
	letter-spacing: .1em;
	font-weight: 500
}

@media screen and (max-width: 750px) {
	.table-info__td {
		margin-top: .4rem;
		font-size: 1.2rem;
		line-height: 2
	}
}

.cardIntroduce {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap
}

@media screen and (max-width: 750px) {
	.cardIntroduce {
		flex-wrap: nowrap
	}
}

.cardIntroduce--flexWrap {
	flex-wrap: wrap
}

@media screen and (max-width: 750px) {
	.cardIntroduce--flexWrap {
		flex-wrap: wrap
	}
}

.cardIntroduce::after {
	content: "";
	display: block;
	max-width: 300px;
	width: 30%
}

.cardIntroduce--wLarge::after {
	content: "";
	display: block;
	max-width: 330px;
	width: 32%
}

.cardIntroduce .cardIntroduce__item {
	max-width: 300px;
	width: 30%
}

.cardIntroduce .cardIntroduce__item:nth-child(n+4) {
	margin-top: 40px
}

.cardIntroduce .cardIntroduce__item--wLarge {
	max-width: 330px;
	width: 32%
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__item {
		max-width: initial;
		width: 100%
	}

	.cardIntroduce .cardIntroduce__item:nth-child(n+2) {
		margin-top: 2rem
	}
}

.cardIntroduce .cardIntroduce__link {
	display: block
}

.cardIntroduce .cardIntroduce__thumb {
	display: block;
	width: 100%;
	height: 0;
	padding-bottom: 60%;
	position: relative
}

.cardIntroduce .cardIntroduce__thumb img {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	-o-object-fit: cover;
	object-fit: cover
}

.cardIntroduce .cardIntroduce__box {
	position: relative;
	display: block;
	padding-right: 20px;
	padding-bottom: 20px;
	z-index: 10
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__box {
		padding-right: 2rem;
		padding-bottom: 2rem
	}
}

.cardIntroduce .cardIntroduce__box::before {
	content: "";
	display: block;
	background-color: #ebebeb;
	opacity: .55;
	width: 100%;
	height: calc(100% - 16px);
	position: absolute;
	top: 16px;
	left: 0
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__box::before {
		height: calc(100% - 1.6rem);
		top: 1.6rem
	}
}

.cardIntroduce .cardIntroduce__box::after {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	bottom: 0;
	background-image: linear-gradient(270deg, rgb(0, 177, 255), rgb(0, 26, 95))
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__box::after {
		height: .2rem
	}
}

.cardIntroduce .cardIntroduce__boxInner {
	display: block;
	background-color: #fff;
	padding: 10px 18px 12px;
	position: relative;
	z-index: 10;
	margin-top: -16px;
	margin-left: -14px
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__boxInner {
		padding: 1rem 1.8rem 1.2rem;
		margin-top: -1.6rem;
		margin-left: -1.4rem
	}
}

.cardIntroduce .cardIntroduce__boxUpper {
	display: flex;
	align-items: center;
	justify-content: space-between
}

.cardIntroduce .cardIntroduce__category {
	max-width: 200px;
	font-family: "Saira", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 10px;
	letter-spacing: 0;
	line-height: 1.6;
	color: #00479d;
	text-align: center;
	display: block;
	padding: 2px 14px;
	border: 1px solid #00479d;
	border-radius: 2px
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__category {
		max-width: 20rem;
		font-size: 1rem;
		padding: .2rem 1.4rem;
		border-radius: .2rem
	}
}

.cardIntroduce .cardIntroduce__day {
	font-weight: 700;
	font-style: normal;
	font-size: 12px;
	letter-spacing: .02em;
	line-height: 1.6;
	color: #a5a5a5
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__day {
		font-size: 1.2rem
	}
}

.cardIntroduce .cardIntroduce__boxCenter {
	margin-top: 7px;
	display: block
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__boxCenter {
		margin-top: .7rem
	}
}

.cardIntroduce .cardIntroduce__title {
	font-family: "Saira", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 14px;
	letter-spacing: .02em;
	line-height: 1.7857142857;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__title {
		font-size: 1.4rem
	}
}

.cardIntroduce .cardIntroduce__boxLower {
	margin-top: 7px;
	display: block
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__boxLower {
		margin-top: .7rem
	}
}

.cardIntroduce .cardIntroduce__text {
	font-family: "Saira", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 12px;
	letter-spacing: .02em;
	line-height: 1.6666666667;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden
}

@media screen and (max-width: 750px) {
	.cardIntroduce .cardIntroduce__text {
		font-size: 1.2rem
	}
}

.cardNews .cardNews__item {
	width: 100%
}

.cardNews .cardNews__item:not(:first-child) {
	margin-top: 30px
}

.cardNews .cardNews__link {
	display: block;
	background-color: #fff;
	box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, .1);
	position: relative
}

.cardNews .cardNews__link::after {
	content: "";
	display: block;
	width: 4px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
	background-image: linear-gradient(180deg, rgb(0, 177, 255), rgb(0, 26, 95))
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__link::after {
		width: 100%;
		height: .4rem;
		right: auto;
		top: auto;
		left: 0;
		bottom: 0;
		z-index: 100;
		background-image: linear-gradient(90deg, rgb(0, 177, 255), rgb(0, 26, 95))
	}
}

.cardNews .cardNews__linkInner {
	padding: 30px;
	display: flex;
	align-items: flex-start;
	gap: 0 30px
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__linkInner {
		padding: 1rem 1rem 2rem;
		gap: 0;
		flex-direction: column
	}
}

.cardNews .cardNews__thumb {
	display: block;
	max-width: 275px;
	width: 275px;
	height: 170px;
	position: relative;
	flex-shrink: 0;
	background-color: #c4c4c4
}

.cardNews .cardNews__thumb img {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	-o-object-fit: cover;
	object-fit: cover
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__thumb {
		max-width: initial;
		width: 100%;
		height: 20rem
	}
}

.cardNews .cardNews__box {
	position: relative;
	display: block;
	z-index: 10
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__box {
		margin-top: .9rem
	}
}

.cardNews .cardNews__boxInner {
	display: block;
	background-color: #fff;
	position: relative;
	z-index: 10
}

.cardNews .cardNews__boxUpper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row-reverse;
	gap: 0 20px
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__boxUpper {
		gap: 0 .6rem
	}
}

.cardNews .cardNews__category {
	max-width: 200px;
	font-family: "Saira", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 10px;
	letter-spacing: 0;
	line-height: 1.6;
	color: #00479d;
	text-align: center;
	display: block;
	padding: 2px 14px;
	border: 1px solid #00479d;
	border-radius: 2px
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__category {
		max-width: 20rem;
		font-size: 1rem
	}
}

.cardNews .cardNews__day {
	font-weight: 700;
	font-style: normal;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 1.4285714286;
	color: #799cc7
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__day {
		font-size: 1.4rem
	}
}

.cardNews .cardNews__boxCenter {
	margin-top: 9px;
	display: block
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__boxCenter {
		margin-top: 1.1rem
	}
}

.cardNews .cardNews__title {
	font-family: "Saira", sans-serif;
	color: #00479d;
	font-weight: 700;
	font-style: normal;
	font-size: 18px;
	letter-spacing: .02em;
	line-height: 1.7777777778;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	padding-bottom: 10px;
	position: relative
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__title {
		font-size: 1.8rem;
		padding-bottom: .8rem
	}
}

.cardNews .cardNews__title::after {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background-color: #00479d;
	position: absolute;
	bottom: 0;
	left: 0
}

.cardNews .cardNews__boxLower {
	margin-top: 19px;
	display: block
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__boxLower {
		margin-top: 1.2rem
	}
}

.cardNews .cardNews__text {
	font-family: "Saira", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 14px;
	letter-spacing: .02em;
	line-height: 1.7857142857;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden
}

@media screen and (max-width: 750px) {
	.cardNews .cardNews__text {
		font-size: 1.4rem
	}
}

.cardCasePaginaviLink {
	display: block;
	background-color: #fff;
	position: relative;
	box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, .1)
}

.cardCasePaginaviLink::after {
	content: "";
	display: block;
	width: 4px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
	background-image: linear-gradient(180deg, rgb(0, 177, 255), rgb(0, 26, 95))
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink::after {
		width: 100%;
		height: .4rem;
		right: auto;
		top: auto;
		left: 0;
		bottom: 0;
		z-index: 100;
		background-image: linear-gradient(90deg, rgb(0, 177, 255), rgb(0, 26, 95))
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__icon {
	background-image: url(../images/common/card_icon_01.png);
	background-size: contain;
	background-repeat: no-repeat;
	width: 87px;
	height: 77px;
	position: absolute;
	top: -26px;
	left: -44px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Saira", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 14px;
	letter-spacing: .02em;
	line-height: 1.2142857143;
	color: #fff;
	text-align: center;
	z-index: 10
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__icon {
		font-size: 1.4rem;
		top: -3.8rem;
		left: -1.8rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__inner {
	display: flex;
	align-items: flex-start;
	position: relative;
	z-index: 1
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__inner {
		gap: 0;
		flex-direction: column;
		padding: 1rem 1rem 2rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__thumb {
	display: block;
	max-width: 230px;
	width: 230px;
	height: 230px;
	position: relative;
	flex-shrink: 0;
	background-color: #c4c4c4
}

.cardCasePaginaviLink .cardCasePaginaviLink__thumb img {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	-o-object-fit: cover;
	object-fit: cover
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__thumb {
		max-width: initial;
		width: 100%;
		height: 20rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__box {
	position: relative;
	display: block;
	z-index: 10
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__box {
		margin-top: .9rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__boxInner {
	display: block;
	background-color: #fff;
	position: relative;
	z-index: 10;
	padding: 30px 40px 0 40px
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__boxInner {
		padding: 0
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__boxUpper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row-reverse;
	gap: 0 20px
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__boxUpper {
		gap: 0 .6rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__category {
	max-width: 200px;
	font-family: "Saira", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 10px;
	letter-spacing: 0;
	line-height: 1.6;
	color: #00479d;
	text-align: center;
	display: block;
	padding: 2px 14px;
	border: 1px solid #00479d;
	border-radius: 2px
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__category {
		max-width: 20rem;
		font-size: 1rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__day {
	font-weight: 700;
	font-style: normal;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 1.4285714286;
	color: #799cc7
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__day {
		font-size: 1.4rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__boxCenter {
	margin-top: 9px;
	display: block
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__boxCenter {
		margin-top: 1.1rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__title {
	font-family: "Saira", sans-serif;
	color: #00479d;
	font-weight: 700;
	font-style: normal;
	font-size: 18px;
	letter-spacing: .02em;
	line-height: 1.7777777778;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	padding-bottom: 10px;
	position: relative
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__title {
		font-size: 1.8rem;
		padding-bottom: 1.2rem;
		-webkit-line-clamp: 3
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__title::after {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background-color: #00479d;
	position: absolute;
	bottom: 0;
	left: 0
}

.cardCasePaginaviLink .cardCasePaginaviLink__boxLower {
	margin-top: 19px;
	display: block
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__boxLower {
		margin-top: 1.2rem
	}
}

.cardCasePaginaviLink .cardCasePaginaviLink__text {
	font-family: "Saira", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 14px;
	letter-spacing: .02em;
	line-height: 1.7857142857;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden
}

@media screen and (max-width: 750px) {
	.cardCasePaginaviLink .cardCasePaginaviLink__text {
		font-size: 1.4rem;
		-webkit-line-clamp: 5
	}
}

.planCard01 {
	border: 2px solid #79acea;
	border-radius: 12px;
	box-shadow: 1px 5px 7px 0px rgba(1, 20, 43, .1);
	overflow: hidden
}

.planCard01 .planCard01__upper {
	background-color: #79acea
}

.planCard01 .planCard01__title {
	font-family: "Saira", sans-serif;
	font-weight: 900;
	font-style: normal;
	font-size: 20px;
	letter-spacing: .1em;
	line-height: 1.5;
	color: #fff;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center
}

@media screen and (max-width: 750px) {
	.planCard01 .planCard01__title {
		height: 6rem;
		font-size: 2rem
	}
}

.planCard01 .planCard01__lower {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 242px;
	height: 100%
}

@media screen and (max-width: 750px) {
	.planCard01 .planCard01__lower {
		min-height: 15.7rem
	}
}

.planCard01 .planCard01__price {
	font-family: "Saira", sans-serif;
	font-weight: 900;
	font-style: normal;
	font-size: 30px;
	letter-spacing: .1em;
	line-height: 1.5;
	color: #79acea;
	display: flex;
	align-items: flex-end
}

@media screen and (max-width: 750px) {
	.planCard01 .planCard01__price {
		font-size: 3rem
	}
}

.planCard01 .planCard01__price--num {
	font-size: 50px;
	letter-spacing: 0;
	padding: 0 7px;
	margin-top: -15px;
	margin-bottom: -10px
}

@media screen and (max-width: 750px) {
	.planCard01 .planCard01__price--num {
		font-size: 5rem;
		padding: 0 .7rem;
		margin-top: -1.5rem;
		margin-bottom: -1rem
	}
}

.planCard01 .planCard01__notice {
	max-width: 210px;
	width: 100%;
	background-color: #79acea;
	font-family: "Saira", sans-serif;
	font-weight: 900;
	font-style: normal;
	font-size: 16px;
	letter-spacing: .1em;
	line-height: 1.75;
	text-align: center;
	color: #fff;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	margin-top: 20px;
	position: relative
}

@media screen and (max-width: 750px) {
	.planCard01 .planCard01__notice {
		max-width: 21rem;
		border-radius: .6rem;
		margin-top: 2rem;
		font-size: 1.6rem
	}
}

.planCard01 .planCard01__notice::before {
	content: "";
	display: block;
	position: absolute;
	transform: translateX(-50%);
	top: -8px;
	left: 50%;
	width: 0;
	height: 0;
	border-style: solid;
	border-right: 5px solid rgba(0, 0, 0, 0);
	border-left: 5px solid rgba(0, 0, 0, 0);
	border-bottom: 10px solid #79acea;
	border-top: 0
}

@media screen and (max-width: 750px) {
	.planCard01 .planCard01__notice::before {
		top: -0.8rem;
		border-right: .5rem solid rgba(0, 0, 0, 0);
		border-left: .5rem solid rgba(0, 0, 0, 0);
		border-bottom: 1rem solid #79acea
	}
}

.planCard01 .planCard01__notice--num {
	font-size: 20px
}

@media screen and (max-width: 750px) {
	.planCard01 .planCard01__notice--num {
		font-size: 2rem
	}
}

.planCard01--color01 {
	border: 2px solid #00b1ff
}

.planCard01--color01 .planCard01__upper {
	background-color: #00b1ff
}

.planCard01--color01 .planCard01__price {
	color: #00b1ff
}

.planCard01--color01 .planCard01__notice {
	background-color: #00b1ff
}

.planCard01--color01 .planCard01__notice::before {
	border-bottom: 10px solid #00b1ff
}

@media screen and (max-width: 750px) {
	.planCard01--color01 .planCard01__notice::before {
		border-bottom: 1rem solid #00b1ff
	}
}

.planCard02 {
	border: 2px solid #00479d;
	border-radius: 12px;
	box-shadow: 1px 5px 7px 0px rgba(1, 20, 43, .1);
	overflow: hidden
}

.planCard02 .planCard02__upper {
	background-color: #79acea;
	background-image: linear-gradient(180deg, #00479D, #00377A)
}

.planCard02 .planCard02__title {
	font-family: "Saira", sans-serif;
	font-weight: 900;
	font-style: normal;
	font-size: 20px;
	letter-spacing: .1em;
	line-height: 1.5;
	color: #fff;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__title {
		height: 6rem;
		font-size: 2rem
	}
}

.planCard02 .planCard02__lower {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 242px;
	height: 100%
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__lower {
		min-height: 24.3rem
	}
}

.planCard02 .planCard02__price {
	display: flex;
	gap: 0 20px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__price {
		gap: 0 2rem
	}
}

.planCard02 .planCard02__notice {
	font-family: "Saira", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 16px;
	letter-spacing: .1em;
	line-height: 1;
	color: #00479d;
	text-align: center;
	border: 2px solid #00479d;
	border-radius: 4px;
	position: relative;
	padding: 2px 12px 4px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__notice {
		font-size: 1.6rem;
		border-radius: .4rem;
		padding: .2rem 1.2 .4rem
	}
}

.planCard02 .planCard02__notice::before {
	content: "";
	display: block;
	position: absolute;
	transform: translateY(-50%);
	right: -13px;
	top: 50%;
	width: 0;
	height: 0;
	border-style: solid;
	border-top: 7px solid rgba(0, 0, 0, 0);
	border-bottom: 7px solid rgba(0, 0, 0, 0);
	border-left: 12px solid #00479d;
	border-right: 0;
	z-index: 10
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__notice::before {
		right: -1.3rem;
		border-top: .7rem solid rgba(0, 0, 0, 0);
		border-bottom: .7rem solid rgba(0, 0, 0, 0);
		border-left: 1.2rem solid #00479d
	}
}

.planCard02 .planCard02__notice::after {
	content: "";
	display: block;
	position: absolute;
	transform: translateY(-50%);
	right: -9px;
	top: 50%;
	width: 0;
	height: 0;
	border-style: solid;
	border-top: 7px solid rgba(0, 0, 0, 0);
	border-bottom: 7px solid rgba(0, 0, 0, 0);
	border-left: 12px solid #fff;
	border-right: 0;
	z-index: 100
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__notice::after {
		right: -0.9rem;
		border-top: .7rem solid rgba(0, 0, 0, 0);
		border-bottom: .7rem solid rgba(0, 0, 0, 0);
		border-left: 1.2rem solid #fff
	}
}

.planCard02 .planCard02__notice--num {
	font-size: 20px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__notice--num {
		font-size: 2rem
	}
}

.planCard02 .planCard02__estimate {
	font-family: "Saira", sans-serif;
	font-weight: 900;
	font-style: normal;
	font-size: 35px;
	letter-spacing: .1em;
	line-height: 1;
	color: #00479d;
	position: relative;
	z-index: 10
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__estimate {
		font-size: 3.5rem
	}
}

.planCard02 .planCard02__estimate::after {
	content: "";
	display: block;
	width: 100%;
	height: 15px;
	position: absolute;
	left: 0;
	bottom: 4px;
	z-index: -1;
	background-image: linear-gradient(-30deg, #00316C, #024A9F, #33C5FF);
	opacity: .24
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__estimate::after {
		height: 1.5rem;
		bottom: .4rem
	}
}

.planCard02 .planCard02__example {
	margin-top: 8px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__example {
		margin-top: .8rem
	}
}

.planCard02 .planCard02__exampleTitle {
	font-family: "Saira", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 18px;
	letter-spacing: .1em;
	line-height: 1;
	position: relative;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__exampleTitle {
		font-size: 1.8rem
	}
}

.planCard02 .planCard02__exampleTitle::before,
.planCard02 .planCard02__exampleTitle::after {
	content: "";
	display: block;
	transform: rotate(-60deg);
	width: 21px;
	border: 0;
	border-top: 2px solid #000;
	margin: 0;
	padding: 0
}

@media screen and (max-width: 750px) {

	.planCard02 .planCard02__exampleTitle::before,
	.planCard02 .planCard02__exampleTitle::after {
		width: 2.1rem
	}
}

.planCard02 .planCard02__exampleOption {
	font-family: "Saira", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 12px;
	letter-spacing: .1em;
	line-height: 1;
	position: relative;
	display: flex;
	align-items: flex-end;
	margin-top: 5px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__exampleOption {
		font-size: 1.2rem;
		margin-top: .5rem
	}
}

.planCard02 .planCard02__exampleOption--num {
	font-size: 20px;
	letter-spacing: 0;
	padding: 0 4px;
	margin-bottom: -1px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__exampleOption--num {
		font-size: 2rem;
		padding: 0 .4rem;
		margin-bottom: -0.1rem
	}
}

.planCard02 .planCard02__exampleOption--arrow {
	display: inline-block;
	vertical-align: middle;
	color: #000;
	line-height: 1;
	position: relative;
	width: 30px;
	height: 1px;
	background: #000;
	margin: 0px 10px 4px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__exampleOption--arrow {
		width: 3rem;
		background: #000;
		margin: 0 1rem .4rem
	}
}

.planCard02 .planCard02__exampleOption--arrow::before {
	content: "";
	width: 3px;
	height: 3px;
	border: 1px solid #000;
	border-left: 0;
	border-bottom: 0;
	transform: rotate(45deg);
	transform-origin: top right;
	position: absolute;
	top: 50%;
	right: -1px;
	box-sizing: border-box
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__exampleOption--arrow::before {
		width: .3rem;
		height: .3rem
	}
}

.planCard02 .planCard02__exampleOption--mark {
	font-size: 14px
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__exampleOption--mark {
		font-size: 1.4rem
	}
}

.planCard02 .planCard02__button {
	margin: 14px auto 0;
	max-width: 260px;
	width: 100%
}

@media screen and (max-width: 750px) {
	.planCard02 .planCard02__button {
		margin: 1.4rem auto 0;
		max-width: 26rem
	}
}

.JsInview img {
	opacity: 0;
	transform: translateY(50%);
	transition: all 1.2s
}

.JsInview.is-active img {
	transform: translateY(0);
	opacity: 1
}

.JsInviewBox__01,
.JsInviewBox__02,
.JsInviewBox__03,
.JsInviewBox__04,
.JsInviewBox__05,
.JsInviewBox__06,
.JsInviewBox__07,
.JsInviewBox__08,
.JsInviewBox__09,
.JsInviewBox__10 {
	filter: blur(3px);
	opacity: 0;
	transition: all 1.2s
}

.JsInviewBox.is-active .JsInviewBox__01,
.JsInviewBox.is-active .JsInviewBox__02,
.JsInviewBox.is-active .JsInviewBox__03,
.JsInviewBox.is-active .JsInviewBox__04,
.JsInviewBox.is-active .JsInviewBox__05,
.JsInviewBox.is-active .JsInviewBox__06,
.JsInviewBox.is-active .JsInviewBox__07,
.JsInviewBox.is-active .JsInviewBox__08,
.JsInviewBox.is-active .JsInviewBox__09,
.JsInviewBox.is-active .JsInviewBox__10 {
	opacity: 1;
	filter: blur(0px)
}

.JsInviewBox__01 {
	transition-delay: 0
}

.JsInviewBox__02 {
	transition-delay: .2s
}

.JsInviewBox__03 {
	transition-delay: .4s
}

.JsInviewBox__04 {
	transition-delay: .6s
}

.JsInviewBox__05 {
	transition-delay: .8s
}

.JsInviewBox__06 {
	transition-delay: 1s
}

.JsInviewBox__07 {
	transition-delay: 1.2s
}

.JsInviewBox__08 {
	transition-delay: 1.4s
}

.JsInviewBox__09 {
	transition-delay: 1.6s
}

.JsInviewBox__10 {
	transition-delay: 1.8s
}

.swiper .swiper-button-prev {
	left: -3.5rem
}

.swiper .swiper-button-prev--02 {
	left: -2.1rem
}

.swiper .swiper-button-next {
	right: -3.5rem
}

.swiper .swiper-button-next--02 {
	right: -2.1rem
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
	width: 4.2rem;
	height: 4.2rem;
	border-radius: 3rem;
	background-color: rgba(0, 71, 157, .4)
}

.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
	content: "";
	display: block;
	background-image: url(../images/common/button_icon_01.svg);
	background-position: center center;
	background-size: contain;
	position: absolute;
	right: 1.6rem
}

@media screen and (max-width: 750px) {

	.swiper .swiper-button-next:after,
	.swiper .swiper-button-prev:after {
		width: 1.1rem;
		height: 2.3rem
	}
}

.swiper .swiper-button-prev:after {
	transform: rotate(-180deg)
}

.swiper .swiper-pagination {
	width: 100%;
	display: flex;
	justify-content: center;
	bottom: 0
}

.swiper .swiper-pagination-bullet {
	display: block;
	width: 3rem;
	height: .3rem;
	border-radius: 0;
	margin: 0 .3rem;
	opacity: 1;
	background-color: #e2e2e2
}

.swiper .swiper-pagination-bullet-active {
	background-color: #00479d
}

.swiper .swiper-pagination--02 .swiper-pagination-bullet-active {
	background-color: #799cc7
}

.JsInviewNeon {
	opacity: 0
}

.show-pc {
	display: block
}

@media screen and (max-width: 750px) {
	.show-pc {
		display: none
	}
}

.show-sp {
	display: none
}

@media screen and (max-width: 750px) {
	.show-sp {
		display: block
	}
}